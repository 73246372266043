import splide from "./splide.min.js"

//menu

const open = document.getElementById("openNav");
open.addEventListener("click", openNav); 

const close = document.getElementById("closeNav");
close.addEventListener("click", closeNav); 

function openNav() {
  document.getElementById("myNav").style.opacity = "1";
  document.getElementById("myNav").style.pointerEvents = "initial";
}

function closeNav() {
  document.getElementById("myNav").style.opacity = "0";
  document.getElementById("myNav").style.pointerEvents = "none";
} 

//menu-accordion

var acc = document.getElementsByClassName("menu-accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    this.classList.toggle("active");
    var panel = this.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  });
}

//accordion

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    this.classList.toggle("active");
    var panel = this.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
      panel.classList.remove('open');
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      panel.classList.add('open');
    }
  });
}


//splide
document.addEventListener("DOMContentLoaded", function () {
    if (document.querySelector(".splide")) {
      var splide = new Splide(".splide", {
        type: "loop",
        heightRatio: 0.3,
        autoWidth: true,
        preloadPages: 3,
        perPage: 4,
        gap: "4rem",
        focus: "center",
        breakpoints: {
          720: {
            heightRatio: 0.4,
          },
        },
      });
  
    //   splide.on( 'mounted', function () {
    //     instance.observe();
    //   } );
  
      splide.mount();
    }
  });